<template>
  <div class="w1200">
    <div class="addus" v-html="content"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content:''
    }
  },
  mounted() {
    this.getJoinUs()
  },
  methods:{
    getJoinUs() {
      this.$api.getJoinUs().then(res=>{
        this.content = res.data.contents
      })
    }
  }
}
</script>
<style>
  .addus img{
    width: 660px;
    margin-left: 40px;
  }
</style>